import { ChakraProvider, theme } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageInConstruction } from "./components/PageInConstruction/PageInConstruction";
import { Home } from "./components/Home/Home";
import { Products } from "./components/Products/Products";
import { Contact } from "./components/Contact/Contact";
import { Header } from "./components/Header/Header";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      {!window.location.pathname.includes("products") && <Header />}

      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<PageInConstruction />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
};
