import { Flex, VStack, Image } from "@chakra-ui/react";
// import ImagesCarousel from "../ImagesCarrousel/ImagesCarrousel";

export const Home = () => {
  return (
    <Flex>
      <VStack>
        <Image src="/carrousel4.png" w={"100%"} />
        {/* <ImagesCarousel /> */}
      </VStack>
    </Flex>
  );
};
