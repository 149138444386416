import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Flex,
  HStack,
  Text,
  Image,
  Input,
  InputRightElement,
  InputGroup,
  Button,
  VStack,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

export const Header = () => {
  const [search, setSearch] = useState("");

  const handleChange = (e: any) => {
    setSearch(e.target.value);
  };

  const handleSearch = () => {
    console.log(search);
  };

  return (
    <Flex>
      <VStack>
        <HStack
          justifyContent={"space-between"}
          py={[4, 6]}
          px={[4, 20]}
          bgImage={"fondoHeader.png"}
          bgRepeat={"no-repeat"}
          bgSize={"cover"}
        >
          <Link href="/">
            <Image src="/logoMazalBlanco.png" w={["45%", "35%"]} p={3} />
          </Link>
          <VStack>
            <Link
              color="white"
              href="https://wa.me/5491145376452"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HStack>
                <Icon as={FaWhatsapp} />
                <Text>+549-11-4537-6452</Text>
              </HStack>
            </Link>
            <Link
              color="white"
              href="mailto:info@mazalimportaciones.com.ar"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HStack>
                <Icon as={MdOutlineEmail} />
                <Text>info@mazalimportaciones.com.ar</Text>
              </HStack>
            </Link>
          </VStack>

          {/* <HStack
            w={["45%", "35%"]}
            border={"2px solid #008037"}
            borderRadius={"5px"}
          >
            <InputGroup size={["xs", "md"]}>
              <Input
                variant={"unstyled"}
                size={["xs", "md"]}
                value={search}
                onChange={handleChange}
                placeholder="¿Qué estás buscando?"
                bgColor={"white"}
                pl={["3px !important", "8px !important"]}
                borderRadius={"3.5px !important"}
              />
              <InputRightElement>
                <Button
                  size={["xs", "md"]}
                  onClick={handleSearch}
                  bgColor={"#008037"}
                  p={"0px !important"}
                  borderRadius={0}
                >
                  <SearchIcon color={"white"} />
                </Button>
              </InputRightElement>
            </InputGroup>
          </HStack> */}
        </HStack>
        <HStack
          justifyContent={"space-around"}
          borderTop={"2px solid #008037"}
          borderBottom={"2px solid #008037"}
          w={"100%"}
          px={4}
          py={1}
          mt={"0px !important"}
        >
          {/* <Menu>
            <MenuButton
              as={Button}
              fontSize={["xs", "md"]}
              bgColor={"transparent"}
              _hover={{ bgColor: "transparent" }}
              rightIcon={<ChevronDownIcon />}
              iconSpacing={0.5}
              p={0}
            >
              PRODUCTOS
            </MenuButton>
            <MenuList>
              <MenuItem>JARDINERIA</MenuItem>
              <MenuItem>HERRAJES</MenuItem>
              <MenuItem>TEJIDOS</MenuItem>
              <MenuItem>JARDINERIA</MenuItem>
              <MenuItem>JARDINERIA</MenuItem>
              <MenuItem>JARDINERIA</MenuItem>
            </MenuList>
          </Menu> */}
          <Link href="/products" _hover={{ textDecoration: "none" }}>
            <Text fontSize={["xs", "md"]}>PRODUCTOS</Text>
          </Link>
          {/* <Link href="" _hover={{ textDecoration: "none" }}>
            <Text fontSize={["xs", "md"]}>NOSOTROS</Text>
          </Link> */}
          <Link href="/contact" _hover={{ textDecoration: "none" }}>
            <Text fontSize={["xs", "md"]}>CONTACTO</Text>
          </Link>
        </HStack>
      </VStack>
    </Flex>
  );
};
