import { ArrowBackIcon, ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Flex,
  HStack,
  Text,
  Image,
  Input,
  InputRightElement,
  InputGroup,
  Button,
  VStack,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Box,
  Grid,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

export const Contact = () => {
  const [search, setSearch] = useState("");

  const handleChange = (e: any) => {
    setSearch(e.target.value);
  };

  const handleSearch = () => {
    console.log(search);
  };

  return (
    <Box
      textAlign="center"
      fontSize="lg"
      backgroundColor={"#12482E"}
      //   h={"66vh"}
    >
      <Grid minH="50vh" p={3} alignItems={"center"}>
        <VStack spacing={6}>
          <Text fontSize={"3xl"} color={"white"}>
            Por consultas y precios comunicarse al
          </Text>
          <Link
            color="white"
            href="https://wa.me/5491145376452"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HStack>
              <Icon as={FaWhatsapp} />
              <Text>+5491145376452</Text>
            </HStack>
          </Link>
          <Link
            color="white"
            href="mailto:info@mazalimportaciones.com.ar"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HStack>
              <Icon as={MdOutlineEmail} />
              <Text>info@mazalimportaciones.com.ar</Text>
            </HStack>
          </Link>
        </VStack>
      </Grid>
    </Box>
  );
};
