import { ArrowBackIcon, ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Flex,
  HStack,
  Text,
  Image,
  Input,
  InputRightElement,
  InputGroup,
  Button,
  VStack,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from "@chakra-ui/react";
import { useState } from "react";
import "./Products.style.css";

export const Products = () => {
  const [search, setSearch] = useState("");

  const handleChange = (e: any) => {
    setSearch(e.target.value);
  };

  const handleSearch = () => {
    console.log(search);
  };

  return (
    <div className="fullscreen">
      <iframe src="https://heyzine.com/flip-book/322d6522af.html" />
      <div className="mask">
        <Link href="/">
          <Icon as={ArrowBackIcon} w={6} h={6} color="black" mt={3} ml={3} />
        </Link>
      </div>
    </div>
  );
};
